import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import BpkImage, {
  withLazyLoading,
  withLoadingBehavior,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkPrice, {
  SIZES,
  ALIGNS,
} from '@skyscanner/backpack-web/bpk-component-price';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
} from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';
import { useMBDContext, buildMBDParam } from '../../common/MBD/useMBDContext';

import type { PopularDestination } from '@skyscanner-internal/falcon-common-types/types/PopularDestinationsProps';

import STYLES from './DestinationContainer.module.scss';

const UNKNOWN_CAR_CLASS = 'UNKNOWN_CAR_CLASS';
const UNSET_CAR_CATEGORY = 'UNSET_CAR_CATEGORY';
const IMAGE_WIDTH = 392;
const IMAGE_HEIGHT = 184;
const FALLBACK_IMAGE_URL =
  'https://content.skyscnr.com/m/2141e25d48558df4/original/fillter-illu_NB.svg?resize=500px:500px&quality=90';
const documentIfExists = typeof window !== 'undefined' ? document : null;
const imageParams = '?crop=960px:640px&quality=60';
const LazyLoadedImage = withLoadingBehavior(
  withLazyLoading(BpkImage, documentIfExists),
);

type DestinationContainerProps = {
  destination: PopularDestination;
  from: string;
  perDay: string;
  index: number;
  componentName?: string;
  relFollow?: boolean;
  mobileScroll?: boolean;
  noShowImage?: boolean;
};

const onClick = (
  props: PopularDestination,
  index: number,
  componentName?: string,
) => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: componentName || COMPONENT_NAME.POPULAR_DESTINATIONS,
    component_action:
      COMPONENT_ACTION.POPULAR_DESTINATIONS.POPULAR_DESTINATIONS_CLICKED,
    destination: {
      destinationName: props.name,
      url: props.redirectUrl,
      price: {
        currency: props?.cheapestCarPrice?.currencyCode,
        amount: Number(props?.cheapestCarPrice?.amount),
        unit:
          props?.cheapestCarPrice?.unit?.toString().replace('UNIT_', '') ||
          'CENTI',
      },
      carType:
        props.popularCarType === UNKNOWN_CAR_CLASS
          ? UNSET_CAR_CATEGORY
          : props.popularCarType,
      index,
    },
  });
};

const DestinationContainer = ({
  componentName = undefined,
  destination,
  from,
  index,
  mobileScroll = false,
  noShowImage = false,
  perDay,
  relFollow = false,
}: DestinationContainerProps) => {
  const imageUrl =
    destination.alternativeImageUrl ||
    destination.imageUrl ||
    FALLBACK_IMAGE_URL;
  const { rawComponentName, vertical } = useMBDContext();

  const mbdName = `${rawComponentName}_item`;

  return (
    <BpkCard
      className={
        mobileScroll
          ? STYLES['DestinationContainer__card-with-width']
          : STYLES.DestinationContainer__card
      }
      padded={false}
      href={destination.redirectUrl}
      rel={relFollow ? '' : 'nofollow'}
      onClick={() => onClick(destination, index, componentName)}
      role="link"
      data-tracking-element-id={`${mbdName}_${index}`}
      data-tracking-common-params={buildMBDParam(mbdName, vertical)}
    >
      {!noShowImage && (
        <div className={STYLES.DestinationContainer__image}>
          <LazyLoadedImage
            src={imageUrl + imageParams}
            altText={destination.subtitle}
            aspectRatio={IMAGE_WIDTH / IMAGE_HEIGHT}
          />
        </div>
      )}
      <div className={STYLES.DestinationContainer__content}>
        <div className={STYLES.DestinationContainer__leftSection}>
          <BpkText tagName="h3" textStyle={TEXT_STYLES.heading4}>
            {destination.subtitle}
          </BpkText>
          {destination.mostPopularCarType && (
            <BpkText
              tagName="p"
              textStyle={TEXT_STYLES.caption}
              className={STYLES.DestinationContainer__additionalInfo}
              dangerouslySetInnerHTML={{
                __html: destination.mostPopularCarType,
              }}
            />
          )}
          {destination.distance && (
            <BpkText
              tagName="p"
              textStyle={TEXT_STYLES.caption}
              className={STYLES.DestinationContainer__additionalInfo}
            >
              {destination.distance}
            </BpkText>
          )}
        </div>
        <div className={STYLES.DestinationContainer__rightSection}>
          <BpkPrice
            size={SIZES.small}
            align={ALIGNS.right}
            leadingText={from}
            price={destination.cheapestPrice}
            trailingText={perDay}
          />
        </div>
      </div>
    </BpkCard>
  );
};

export default DestinationContainer;
